<template>
  <section>
    <ServicesTopBar />
    <ServiceList />
    <SiteFooter />
  </section>
</template>

<script>
import ServicesTopBar from '../components/services/ServicesTopBar.vue'
import ServiceList from '../components/services/ServiceList.vue'
import SiteFooter from '../components/site/SiteFooter.vue'

export default {
  components: { ServicesTopBar, ServiceList, SiteFooter},
}
</script>

<style scoped>

</style>
