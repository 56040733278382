<template>
  <div class="service_list_box">
    <!-- <div class="service_menu_list" v-if="business_profiles.length > 0"> -->

    <!-- <app-collapse>

        <app-collapse-item
          title="Services"
          :is-visible="true"
        >
          {{ }}

          <b-badge
            v-for="(elm, keys) in categories"
            :key="keys"
            variant="light-success"
            class="mr-1"
            style="margin-bottom: 10px; cursor: pointer;"
          ><feather-icon
            icon="TagIcon"
            class="mr-25"
          /> {{ elm.name }}</b-badge>
        </app-collapse-item> -->

    <!-- <app-collapse-item
          title="Counties"
        >
          <b-form-group label="">
            <span class="text-danger">{{ county_options.length === 0 ? 'Please select state to view county' : '' }}</span>

            <b-form-radio-group
              v-model="county_selected"
              :options="county_options"
              name="counties"
              stacked
              class="pb-2 app_radio_box"
              @change="selectCounty"
            />
          </b-form-group>
        </app-collapse-item> -->

    <!-- <app-collapse-item
          title="Categories"
          :is-visible="true"
        >
          <b-form-group label="">

            <b-form-radio-group
              v-model="selected"
              :options="category_options"
              name="categories"
              stacked
              class="pb-2 app_radio_box"
              @change="selectCategory"
            />
          </b-form-group>
        </app-collapse-item> -->

    <!-- <app-collapse-item
          title="Sub Categories"
        >
          <b-form-group label="">
            <b-form-radio-group
              v-model="selected_sub_category"
              :options="sub_category_options"
              name="sub_category"
              stacked
              class="pb-2 app_radio_box"
              @change="selectSubCategory"
            />
          </b-form-group>
        </app-collapse-item> -->
    <!-- </app-collapse>
    </div> -->
    <div class="service_item_list">
      <div

        class="row service_list_box"
        :style="`height: ${search_message.length > 0 ? '80% !important' : 'auto !important'}`"
      >
        <div
          class="col-12"
        >
          <div class="sorting_box_view">
            <span class="search_result_text">Services - {{ search_result }}({{ business_profiles.length }})</span>
            <div

              class="sorting_box"
              @click="sortProfile"
            >
              <img
                v-if="sort_type === 'DESC'"
                v-b-tooltip.hover.top="'sort alphabetically'"
                :src="SortDown"
                alt="sort"
              >
              <img
                v-if="sort_type === 'ASC'"
                v-b-tooltip.hover.top="'sort alphabetically'"
                :src="SortUp"
                alt="sort"
              >
            </div>
            <div

              class="sorting_box"
              @click="sortRating"
            >
              <img
                v-if="sort_rating_type === 'DESC'"
                v-b-tooltip.hover.top="'sort by ratings'"
                :src="SortRatingDown"
                alt="sort"
                class="rating_sort_image"
              >
              <img
                v-if="sort_rating_type === 'ASC'"
                v-b-tooltip.hover.top="'sort by ratings'"
                :src="SortRatingUp"
                alt="sort"
                class="rating_sort_image"
              >
            </div>

          </div>

        </div>
        <div
          v-if="business_profiles.length < 1"
          class="not_found_box mt-3"
        >
          <img
            :src="notFoundImage"
            alt="empty"
          >
          <h3>{{ last_search.county_id > 0 && categories.length < 1 ? `Services in ${ last_search.county_name } coming soon.` : search_message }}</h3>
        </div>
        <!-- card section start -->
        <div
          v-else
          class="col-12"
        >
          <div
            class="row"
          >

            <div
              v-for="(item, index) in business_profiles"

              :key="index"
              class="col-12 col-md-6 col-lg-4 mb-2"
              @click="showDetails(item)"
            >
              <div class="service_card_box">
                <div class="service_image_box">
                  <div
                    v-lazy:background-image="getImage(item.cover)"
                    class="service_image"
                  >
                    <div
                      v-if="user_type === 'member' || userData.is_vendor === true"
                      class="tag_box"
                    >
                      <div
                        v-b-tooltip.hover.top="item.favorite ? 'Remove from favourite' : 'Add to favourite'"
                        class="book-mark-box"
                        :class="item.favorite ? 'like' : ''"
                        @click.stop="addToFavourite(item)"
                      >
                        <img
                          v-if="item.favorite"
                          :src="FavoriteIcon"
                          alt="sort"
                        >
                        <img
                          v-else
                          :src="FavoriteIcon2"
                          alt="sort"
                        >
                      </div>

                      <div
                        v-b-tooltip.hover.top="'Recommend Service'"
                        class="book-mark-box"
                        @click.stop="showRecomModal(item)"
                      >
                        <feather-icon icon="CornerUpRightIcon" />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card_details">
                  <div class="tag-view">

                    <span> {{ item.category }}</span>
                    <span>{{ item.state }}</span>
                  </div>

                  <h3 class="list_heading">
                    {{ item.name | setTruncate(34, "...") }}
                  </h3>

                  <div class="review_view">
                    <span />
                    <span>({{ item.total_reviews > 0 ? item.total_reviews : 'No' }} {{ item.total_reviews > 1 ? 'reviews' : 'review' }})</span>
                    <b-form-rating
                      v-model="item.rating"
                      variant="warning"
                      inline
                      no-border
                      readonly
                      class="raiting_box"
                    />
                    <span v-if="item.rating > 0">{{ item.rating }} out 5</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div
          v-if="business_profiles.length === 0 && search_result.length > 0 && search_result.length > 0 && profile_params.states.length > 0 "
          class="not_found_box mt-3"
        >
          <img
            :src="notFoundImage"
            alt="empty"
          >
          <h3>No Data Found</h3>
        </div> -->
      </div>

      <div
        v-if="business_profiles.length >= 12 || profile_param.page > 1"
        class="pagination_box"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="profile_params.per_page"
          size="lg"
          align="center"
          @change="onPageChange"
        />
      </div>
    </div>
    <!-- <div class="service_map_view">
      <l-map
        :center="[center.lat, center.lng]"
        :zoom="zoom"
        style="height: 89vh; width: 100%"
      >
        <l-tile-layer
          :url="url"
          :attribution="attribution"
        />

        <l-marker
          v-for="(marker, index) in markers"
          :key="index"
          :lat-lng="marker.latLng"
          @click="showDetails(marker)"
        >
          <l-icon
            :icon-url="marker.iconUrl"
            :icon-size="marker.iconSize"
            :icon-anchor="marker.iconAnchor"
          />
          <l-tooltip
            :direction="marker.tooltipDirection"
            :offset="marker.tooltipOffset"
            :permanent="marker.tooltipPermanent"
          >
            <div class="map_card_box">
              <div
                class="map_card_bg"
                :style="getImage(marker.cover)"
              />
              <p class="">
                {{ setTextTruncate(marker.name, 26, '...') }}
              </p>
            </div>
          </l-tooltip>
        </l-marker>
      </l-map>
    </div> -->

    <b-modal
      id="recom-modal"
      hide-footer
    >
      <template #modal-title>
        Recomend Business : <small class="text-info">{{ selected_business_title }}</small>
      </template>
      <b-card-text>
        <RecomendForm
          :id="business_id"
          :hide="hide"
        />
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import {
  BCardText, BFormGroup, VBTooltip, BFormRadioGroup, BButton, BModal, VBModal, BPagination,
  BRow,
  BCol,
  BBadge,
  BFormRating,
} from 'bootstrap-vue'
import notFoundImage from '@/assets/images/icons/empty.svg'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-duplicates
import {
  LMap,
  LTileLayer,
  LMarker,
  LTooltip,
  LControlZoom,
  LControlAttribution,
  LControlScale,
  LControlLayers,
  LIcon,
} from 'vue2-leaflet'
import SortDown from '../../../assets/images/icons/sortdown.png'
import SortUp from '../../../assets/images/icons/sortup.png'
import SortRatingDown from '../../../assets/images/icons/generic-rating-sorting-asc.png'
import SortRatingUp from '../../../assets/images/icons/generic-rating-sorting-desc.png'
import FavoriteIcon from '../../../assets/images/icons/heart-deep.png'
import FavoriteIcon2 from '../../../assets/images/icons/heart-plain.png'
import StarIcon from '../../../assets/images/icons/star.png'
// import { latLngBounds, Icon } from 'leaflet'
import serviceImage from '../../../assets/images/service/service1.jpg'
import RecomendForm from './RecomendForm.vue'
import 'leaflet/dist/leaflet.css'
// eslint-disable-next-line no-underscore-dangle
// delete Icon.Default.prototype._getIconUrl
// Icon.Default.mergeOptions({
//   // eslint-disable-next-line global-require
//   iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
//   // eslint-disable-next-line global-require
//   iconUrl: require('leaflet/dist/images/marker-icon.png'),
//   // eslint-disable-next-line global-require
//   shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
// })
/* eslint-disable global-require */

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BFormGroup,
    BFormRating,
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LControlZoom,
    LControlAttribution,
    LControlScale,
    LControlLayers,
    LIcon,
    BRow,
    BCol,
    BFormRadioGroup,
    BModal,
    BCardText,
    BPagination,
    BButton,
    BBadge,
    RecomendForm,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  data() {
    return {
      SortUp,
      SortDown,
      SortRatingDown,
      SortRatingUp,
      StarIcon,
      notFoundImage,
      state_selected: [],
      selected_business_title: '',
      currentPage: 1,
      county_selected: [],
      selected_sub_category: [],
      business_id: null,
      show_county: false,
      FavoriteIcon,
      FavoriteIcon2,
      selected: [],
      is_favourite_items: [],
      serviceImage,
      zoom: 13,
      perPage: 12,
      recommend_page: 1,
      sort_type: 'DESC',
      sort_rating_type: 'DESC',
      user_type: '',
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: 'Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors',
    }
  },
  computed: {
    business_profiles() {
      return this.$store.state.businessProfile.business_profiles
    },
    search_message: {
      get() {
        return this.$store.state.businessProfile.search_message
      },
      set(value) {
        return value
      },
    },
    categories() {
      return this.$store.state.category.categories_profile
    },
    search_result: {
      get() {
        return this.$store.state.businessProfile.search_result_text
      },
    },
    last_search() {
      return this.$store.state.businessProfile.active_data
    },
    enable_sort() {
      return this.$store.state.businessProfile.enable_sort
    },
    markers() {
      return this.business_profiles.map(item => ({
        id: item.id,
        latLng: [item.lat, item.lng],
        name: item.name,
        cover: item.cover,
        draggable: false,
        visible: true,
        iconUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconSize: [25, 41],
        iconAnchor: [12.5, 41],
        tooltipDirection: 'top',
        tooltipOffset: [0, 0],
        tooltipPermanent: true,
      }))
    },
    profile_params() {
      return this.$store.state.businessProfile.param
    },
    totalRows() {
      return this.$store.state.businessProfile.total_count
    },
    profile_param: {
      get() {
        return this.$store.state.businessProfile.param
      },
      set(value) {
        return value
      },
    },
    center: {
      get() {
        return this.$store.state.businessProfile.center
      },
      set(value) {
        return value
      },
    },
    states() {
      return this.$store.state.state.states
    },
    counties() {
      return this.$store.state.county.counties
    },
    county_options() {
      return this.counties.map(item => ({
        text: item.name, value: item.id,
      }))
    },
    state_options() {
      return this.states.map(item => ({ text: `${item.name}, ${item.abbreviation}`, value: item.id }))
    },
    category_options() {
      return this.categories.map(item => ({ text: item.name, value: item.id }))
    },
    sub_categories() {
      return this.$store.state.subCategory.sub_categories
    },
    sub_category_options() {
      return this.sub_categories.map(item => ({ text: item.name, value: item.id }))
    },
    userData() {
      return this.$store.state.user.profile
    },
  },
  mounted() {
    // this.loadAllProfile()
    this.getState()
    this.user_type = localStorage.getItem('gts_user_type')
  },

  methods: {
    getBusinessProfile() {
      this.$store.dispatch('businessProfile/getBusinessProfileListing', this.profile_params)
    },
    loadAllProfile() {
      this.$store.dispatch('businessProfile/emptyParam')
      this.getBusinessProfile()
    },
    sortProfile() {
      this.$store.dispatch('businessProfile/enableSort', true)
      this.$store.dispatch('businessProfile/updateMessage', '')
      if (this.sort_type === '' || this.sort_type === 'DESC') {
        this.sort_type = 'ASC'
      } else if (this.sort_type === 'ASC') {
        this.sort_type = 'DESC'
      }
      this.profile_params.sort.direction = this.sort_type
      this.profile_params.sort.field = 'name'
      this.getBusinessProfile()
    },
    sortRating() {
      if (this.sort_rating_type === '' || this.sort_rating_type === 'DESC') {
        this.sort_rating_type = 'ASC'
      } else if (this.sort_rating_type === 'ASC') {
        this.sort_rating_type = 'DESC'
      }
      this.profile_params.sort.direction = this.sort_rating_type
      this.profile_params.sort.field = 'rating'
      this.$store.dispatch('businessProfile/enableSort', true)
      this.$store.dispatch('businessProfile/updateMessage', '')
      this.getBusinessProfile()
    },
    onPageChange(page) {
      this.profile_param.page = page
      this.getBusinessProfile()
    },
    // getImage(image) {
    //   if (image !== null || image !== '') {
    //     return `background: url(${process.env.VUE_APP_STORAGE_PATH + image})`
    //   }
    //   return image
    // },
    getImage(image) {
      return process.env.VUE_APP_STORAGE_PATH + image
    },
    setTextTruncate(text, length, clamp) {
      // eslint-disable-next-line no-param-reassign
      clamp = clamp || '...'
      const node = document.createElement('div')
      node.innerHTML = text
      const content = node.textContent
      return content.length > length ? content.slice(0, length) + clamp : content
    },

    getState() {
      return this.$store.dispatch('state/getStates')
    },
    selectState(id) {
      this.profile_param.states = [id]
      this.show_county = true
      this.$store.dispatch('county/getCounties', id)
      this.getBusinessProfile()
    },
    selectCounty(id) {
      this.profile_param.counties = [id]
      // const data = this.counties.filter(item => item.id === id)[0]
      // this.center.lat = data.lat
      // this.center.lng = data.lng
      this.getBusinessProfile()
    },
    selectCategory(id) {
      this.profile_param.categories = [id]
      // this.$store.dispatch('subCategory/getSubCategories', id)
      this.getBusinessProfile()
    },
    selectSubCategory(item) {
      this.profile_param.categories = [item]
      this.getBusinessProfile()
    },
    getCategory() {
      return this.$store.dispatch('category/getCategories')
    },
    showRecomModal(item) {
      this.business_id = item.id
      this.selected_business_title = item.name
      this.$bvModal.show('recom-modal')
    },
    hide() {
      this.$bvModal.hide('recom-modal')
      this.business_id = null
    },
    showDetails(item) {
      localStorage.setItem('gts_profile_id', item.id)
      this.$store.dispatch('businessProfile/getSingleBusinessProfile', item.id)
      const slug = item.name.replaceAll(' ', '-').toLocaleLowerCase()
      this.$router.push(`/services/${slug}`)
    },
    alert(item) {
      // eslint-disable-next-line no-alert
      alert(`this is ${JSON.stringify(item)}`)
    },
    addToFavourite(item) {
      const data = {
        member_id: this.userData.id,
        business_profile_id: item.id,
        favorite: !item.favorite,
      }

      this.$store.dispatch('businessProfile/addToFavourite', { item: data, param: this.profile_params })
      // this.$router.push({ name: ''}).catc
      const dom = this
      setTimeout(() => {
        dom.getBusinessProfile()
      }, 100)
    },
    addMarker() {
      const newMarker = {
        position: { lat: 50.5505, lng: -0.09 },
        draggable: true,
        visible: true,
      }
      this.markers.push(newMarker)
    },
    removeMarker(index) {
      this.markers.splice(index, 1)
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
